<template>
  <div id="app">
    <div class="login-bar" v-if="!$HUDONGFENG">
      <div v-show="isLogin">
        <span>{{ loginTxt }}</span>
        <span class="loginout" style="color: #3f89ec;" v-on:click="loginOut">退出登录</span>
      </div>
      <!-- <router-link  class="link" to="/introduction">公司介绍</router-link> -->
      <div v-show="loginbarIsShow">
        <router-link class="link" to="/login">注册</router-link>
        <router-link class="link" to="/login">登录</router-link>
      </div>
    </div>
    <hbanner></hbanner>
    <hnav></hnav>
    <section class="content">
      <router-view></router-view>
    </section>
    <footer>
      <template v-if="$HUDONGFENG">
        <div height="80" class="foot-link">
          <span>互动峰科技（北京）有限公司</span>
          <span>电话：010-59798875</span>
        </div>
        <div height="80" style="color: #999;text-align: center;margin-bottom: 5px">
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow" style="text-decoration:none;">京ICP备06057344号-1 </a>
          互动峰科技（北京）有限公司版权所有 Copyright 2023
        </div>
      </template>
      <template v-else>
        <div height="80" class="foot-link">
          <span>银川好大夫互联网医院有限公司</span>
          <span>电话：010-59798875</span>
        </div>
        <div height="80" style="color: #999;text-align: center;margin-bottom: 5px">
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow" style="text-decoration:none;">宁ICP备16000822号-2 </a>
          银川好大夫互联网医院有限公司版权所有 Copyright 2023
        </div>
        <div height="80" style="color: #999;text-align: center;">
          <!-- <router-link class="m-u-l-link" to="/zizhi" style="text-decoration: none">互联网药品信息服务资格证书 (京)-非经营性-2021-0042</router-link> -->
        </div>
      </template>
    </footer>
  </div>
</template>

<script>
import hbanner from './components/banner';
import hnav from './components/nav'

export default {
  components: { hbanner, hnav },
  name: "App",
  data: function () {
    return {
      loginbarTxt: 0
    }
  },
  methods: {
    loginOut() {
      sessionStorage.removeItem('userName');
      this.$store.commit("setUserName", "");
    }
  },
  computed: {
    userName() {
      return this.$store.getters.username
    },
    isLogin() {
      return this.$store.getters.isLogin
    },
    loginbarIsShow() {
      return !this.isLogin
    },
    loginTxt: function () {
      if (this.userName) {
        return `${this.userName},您好`
      } else {
        return ""
      }
    }
  }
};
</script>

<style>
body,
html {
  padding: 0 !important;
}

* {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

#app {
  max-width: 890px;
  margin: 0 auto;
  /* background: #f4f4f4; */
  font-size: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  position: relative;
}

.content {
  min-height: calc(100vh - 300px);
  padding: 0;
  margin: 0;
  background-color: #ffffff;
}

.login-bar {
  position: relative;
  background: #ebeef0;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-size: 12px;
  padding: 6px 15px;
  box-sizing: border-box;
}

.loginout {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.link {
  display: inline-block;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  float: right;
  padding-right: 10px;
}

footer,
footer a {
  color: #999;
  font-size: 12px;
}

.foot-link {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 5px;
}

.foot-link span {
  padding-right: 10px;
}
</style>
